@keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Cairo', sans-serif;
}

.page {
  padding-top: 100px;
  padding-bottom: 150px;
  max-width: 1080px;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    padding-top: 90px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.login {
    max-width: 100vw;
  }
}

.icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.icon.logout {
  background-image: url('../img/logout.svg');
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.loading {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 67px;
  height: 67px;
  transform: translate(-33.5px, -33.5px) scale(0.335) translate(33.5px, 33.5px);
  .circle {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &.secondary {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }
}

.icon.listMenu {
  background-image: url('../img/listMenu.svg');
  height: 25px;
  width: 25px;
  @media (max-width: 767px) {
    height: 20px;
    width: 20px;
  }
}

.icon.edit {
  background-image: url('../img/i-edit.svg');
  height: 25px;
  width: 25px;
}

.icon.remove {
  background-image: url('../img/i-delete.svg');
  height: 25px;
  width: 25px;
}

h2 {
  font-size: 24px;
  text-align: center;
  font-weight: normal;
}

h3 {
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin: -10px 0 0;
  font-size: 14px;
}

.appBar {
  box-shadow: none !important;
  height: 100px;
}

.flexRow {
  display: flex;
  justify-content: center;
}

.inputSearch {
  background-image: url('../img/search.svg');
  background-size: 20px;
  background-position: center left 20px;
  background-repeat: no-repeat;
  padding: 0px 10px 0px 55px !important;
  line-height: 2;
  border-bottom: 1px solid #777;
  input {
    padding: 5px;
    font-size: 24px;
  }
}

.direction {
  background-image: url('../img/rotation.svg');
  width: 35px;
  height: 35px;
  margin: 0 10px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  transform: scale(1);
  transition: transform 0.3s;
  display: block;
  cursor: pointer;
  &.white {
    background-image: url('../img/rotation-white.svg');
  }
  &.ccw {
    transform: scaleX(-1);
  }
}

.button {
  border-radius: 50px !important;
  height: 60px;
  font-size: 22px !important;
  text-transform: none !important;
  color: white !important;
  width: 250px;
  box-shadow: none !important;
  font-weight: bold !important;
  &.secondary {
    background-color: white;
    color: #c8c8c8 !important;
    border: 2px solid #c8c8c8 !important;
    &:hover {
      background-color: #c8c8c8;
      color: white !important;
    }
  }
}

.graphWrapper {
  border-radius: 15px;
  height: 200px;
  padding: 20px 5px 10px;
  margin: 20px auto;
  cursor: pointer;
  @media (max-width: 767px) {
    height: 150px;
  }
  line {
    stroke: rgba(250,250,250,.1) !important;
  }
  text {
    fill: #fff !important;
  }
  table {
    margin: 0px -5px;
    width: 120px !important;
  }
}

.listHistory {
  margin: 20px auto;
  padding-left: 0;
  @media (max-width: 1024px) {
    margin: 20px -20px;
  }

}

.expandableItem {
  list-style-type: none;
  border-top: 1px solid #c8c8c8;
  padding: 3px 0;
  p span {
    font-size: 14px;
    display: inline-block;
    margin-left: 20px;
  }

  &.allcore360 {
    li {
      margin-left: -40px;
      justify-content: flex-start;
      padding: 0;
      margin-bottom: 20px;
    }
  }
  .listMenu {
    cursor: pointer;
    z-index: 5;
    position: relative;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
    margin-right: -10px;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }
  [role="button"] {
    padding-left: 20px;
  }
  .listActions {
    padding-right: 0;
    position: absolute;
    right: -120px;
    opacity: 0;
    top: 0;
    bottom: 0;
    transition: all 300ms cubic-bezier(.17,.89,.33,1.15);
    display: flex;
    align-items: center;
    &.active {
      right: 0px;
      opacity: 1;
    }
    .icon {
      margin-right: 30px;
    }
  }
  &:last-child {
    border-bottom: 1px solid #c8c8c8;
  }
}

.tabBar {
  @media (max-width: 767px) {
    .TabBar-tabsRoot-66 {
      height: 60px;
    }
    [role="tab"] {
      padding-top: 10px;
    }
  }
}
